<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="开票日期">
              <a-range-picker @change="onCreateDateChange" :default-value="[queryParam.open_begin_date, queryParam.open_end_date]"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="票号">
              <a-input v-model="queryParam.invoice_no" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="客户名称">
              <a-input v-model="queryParam.customer_name" />
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="部门">
              <a-select
                showSearch
                placeholder="请选择部门"
                style="width: 100%"
                v-model="queryParam.department"
              >
                <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票种类">
              <a-select
                v-model="queryParam.invoice_type_code"
                allowClear
                placeholder="发票种类"
              >
                <a-select-option v-for="op in invoiceTypeCodeOps" :key="op.code">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="发票状态">
              <a-select
                v-model="queryParam.invoice_status"
                allowClear
                placeholder="发票种类"
              >
                <a-select-option v-for="op in invoiceStatusOps" :key="op.code">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="loadData">查询</a-button>
      <!-- <a-button type="primary" icon="plus" @click="handleCreate">补录发票</a-button> -->
      <a-button type="danger" icon="plus" @click="handleApplyInvoice">红冲发票</a-button>
      <a-button type="primary" icon="plus" @click="handleSendEmail">发送邮件</a-button>
      <!-- <a-popover
        v-model="popVisible"
        title="选择日期"
        trigger="click">
        <a slot="content">
          <p><a-range-picker @change="monthDateChange"/></p>
          <p style="text-align: right"><a-button type="primary" @click="exportInvoiceStatic">确定</a-button></p>
        </a>
        <a-button type="primary" icon="download">发票统计</a-button>
      </a-popover> -->
      <a-popover
        v-model="newPopVisible"
        title="选择日期"
        trigger="click">
        <a slot="content">
          <p><a-range-picker @change="monthDateChange"/></p>
          <p style="text-align: right"><a-button type="primary" @click="newExportInvoiceStatic">确定</a-button></p>
        </a>
        <a-button type="query" icon="download">发票统计（新）</a-button>
      </a-popover>
      <a-button type="primary" icon="download" @click="exportInvoiceDetail">发票明细</a-button>
      <a-button type="primary" icon="download" @click="batchHandleOpenEinvoiceUrl">电子发票下载</a-button>
      <a-button type="danger" icon="plus" @click="handleOnlyApllyInvoice()">单独开票</a-button>
    </div>

    <a-table
      ref="table"
      size="middle"
      row-key="id"
      :columns="columns"
      :data-source="invoiceLogdata"
      :pagination="pagination"
      :scroll="{ x: 'max-content' }"
      @change="handleChangeTable"
      :row-selection="{ selectedRowKeys: this.selectedRowKeys, selectedRows: this.selectedRows, onChange: this.onSelectChange }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleOpenEinvoiceUrl(record)"
             v-if="record.invoice_type_code&&(record.invoice_type_code === '026'||record.invoice_type_code === '028'||record.invoice_type_code === '01'||record.invoice_type_code === '02')">下载</a>
          <a @click="handlePrintInvoice(record)" :style="{color:record.is_print===1?'red':'green'}" v-else>打印</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a style="color:red" @click="handleCancelOrNormal(record)" v-if="record.status && record.status !== 'redoff' && record.status === 'normal'">
            作废</a>
          <a-divider type="vertical" v-if="record.status && record.status !== 'redoff' && record.status === 'normal'"/>
          <a style="color:green" @click="handleApplyInvoice('openAgain', record)" v-if="record.status && record.status !== 'normal' && record.status !== 'normalred'">
            重开</a>
          <a-divider type="vertical" v-if="record.status && record.status !== 'normal'"/>
          <a style="color:red" @click="handleApplyRedInfoNo(record)" v-if="record.status && record.status === 'normal'">
            申请红字</a>
          <!-- <a style="color:red" @click="handleDelete(record)">删除</a> -->
        </template>
      </span>
    </a-table>

    <a-modal
      :visible="applyInvoiceVisible"
      :confirmLoading="applyInvoiceLoading"
      :width="1200"
      :maskClosable="false"
      :title="applyInvoiceTile"
      @cancel="applyInvoiceCancel"
      @ok="applyInvoiceOk"
    >
      <a-form-model layout="inline" :model="applyInvoiceForm" :rules="applyInvoiceRules" ref="applyInvoiceForm">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="原发票号" prop="original_invoice_no" v-if="!this.onlyApplyInvoice">
              <a-input v-model.trim="applyInvoiceForm.original_invoice_no" @blur="handleQueryOriginalInvoice"/>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="原发票代码">
              <a-input v-model="applyInvoiceForm.original_invoice_code" style="width:150px" @blur="handleQueryOriginalInvoice"/>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="8">
            <a-form-model-item label="原因" prop="red_invoice_label" v-if="!this.openAgain&&!this.onlyApplyInvoice">
              <a-select
                v-model="applyInvoiceForm.red_invoice_label"
                showSearch
                allowClear
                style="width:100px"
              >
                <a-select-option v-for="op in redInvoiceLabelList" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="红字编号" prop="red_info_no" v-if="!this.openAgain&&!this.onlyApplyInvoice">
              <a-input v-model.trim="applyInvoiceForm.red_info_no" style="width:160px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属部门" v-if="this.onlyApplyInvoice">
              <a-select
                v-model="applyInvoiceForm.department"
                style="width:160px"
                allowClear
                placeholder="请选择部门"
                @change="queryTaxtrayListByDepartment()"
              >
                <a-select-option v-for="op in this.GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700">开票类型</a-divider>
          <a-col :span="18">
            <a-form-model-item prop="invoice_type_code">
              <a-radio-group v-model="applyInvoiceForm.invoice_type_code">
                <!-- <a-radio-button value="004">
                  增值税专用发票
                </a-radio-button>
                <a-radio-button value="007">
                  增值税普通发票
                </a-radio-button>
                <a-radio-button value="028">
                  增值税电子专用发票
                </a-radio-button>
                <a-radio-button value="026">
                  增值税电子普通发票
                </a-radio-button> -->
                <a-radio-button value="01">
                  全电专用发票
                </a-radio-button>
                <a-radio-button value="02">
                  全电普通发票
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="many_together">
              <a-radio-group v-model="applyInvoiceForm.many_together">
                <a-radio-button value="N">
                  单票
                </a-radio-button>
                <a-radio-button value="Y">
                  多票
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.invoice_type">
                <a-radio-button value="1">
                  负数
                </a-radio-button>
                <a-radio-button value="0">
                  正数
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.taxation_method">
                <a-radio-button value="0">
                  普通征税
                </a-radio-button>
                <a-radio-button value="1">
                  差额征税
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.price_tax_mark" @change="calcTotalPrice(true)">
                <a-radio-button value="1">
                  含税
                </a-radio-button>
                <a-radio-button value="0">
                  不含税
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-radio-group v-model="applyInvoiceForm.invoice_list_mark">
                <a-radio-button value="0">
                  明细
                </a-radio-button>
                <a-radio-button value="1">
                  清单
                </a-radio-button>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="tax_tray_id">
              <a-select
                v-model="applyInvoiceForm.tax_tray_id"
                showSearch
                allowClear
                style="width: 240px"
                @change="changeSaleInvoiceInfo"
              >
                <a-select-option v-for="op in taxtrayList" :key="op.id">{{ op.tax_tray_name+op.sale_company_tax_no }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700">购买单位:
            <a-select
              showSearch
              label-in-value
              allowClear
              placeholder="请选择购买单位"
              style="width: 360px"
              :value="invoiceBuyerInfo"
              :filter-option="false"
              :not-found-content="buyerfetching ? undefined : null"
              @search="getInvoiceBuyerInfo"
              @change="changeInvoiceBuyerInfo"
            >
              <template v-if="buyerfetching" #notFoundContent>
                <a-spin size="small" />
              </template>
              <a-select-option v-for="op in invoiceBuyerOps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-divider>
          <a-col :span="12">
            <a-form-model-item label="名称" prop="buyer_name">
              <a-input v-model="applyInvoiceForm.buyer_name" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税号">
              <a-input v-model="applyInvoiceForm.buyer_tax_no" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="地址">
              <a-input v-model="applyInvoiceForm.buyer_address_phone" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="电话">
              <a-input v-model.trim="applyInvoiceForm.buyer_telphone" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行名称">
              <a-input v-model="applyInvoiceForm.buyer_bank_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行账户">
              <a-input v-model.trim="applyInvoiceForm.buyer_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700">开票内容<a-tag :color="applyInvoiceForm.price_tax_mark==='1'?'green':'red'">{{applyInvoiceForm.price_tax_mark==='1'?'含税(单价、金额)':'不含税(单价、金额)' }}</a-tag></a-divider>
          <a-col :span="24">
            <edit-table
              :columns="invoiceSubjectColumns"
              :sourceData="invoiceSubjectData"
              :scroll="true"
              :operate="{ add: true, del: true, alldel: false }"
              @getTableDate="getInvoiceSubjectTableDate"></edit-table>
          </a-col>
          <a-col :span="24">
            <span style="font-weight:700;color:red">含税总额：{{ this.totalInvoiceAmount }}</span>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700">销货单位</a-divider>
          <a-col :span="12">
            <a-form-model-item label="名称" prop="sale_company_name">
              <a-input v-model="applyInvoiceForm.sale_company_name" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="税号" prop="sale_company_tax_no">
              <a-input v-model="applyInvoiceForm.sale_company_tax_no" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="地址电话" prop="sale_company_address_mobile">
              <a-input v-model="applyInvoiceForm.sale_company_address_mobile" style="width:360px"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="银行账户" prop="sale_company_bank_account">
              <a-input v-model="applyInvoiceForm.sale_company_bank_account" style="width:360px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="票面备注">
              <a-textarea v-model="applyInvoiceForm.remarks" style="width:720px"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-divider orientation="left" style="font-weight:700"></a-divider>
          <a-col :span="6">
            <a-form-model-item label="收款人" prop="payee">
              <a-input v-model="applyInvoiceForm.payee" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="复核人" prop="checker">
              <a-input v-model="applyInvoiceForm.checker" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="开票人" prop="drawer">
              <a-input v-model="applyInvoiceForm.drawer" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="formVisible"
      :confirmLoading="confirmLoading"
      :width="900"
      :maskClosable="false"
      :title="modalFlag === 'create' ? '新增发票' : '编辑发票'"
      @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="发票类型" prop="type">
              <a-select
                placeholder="请选择发票类型"
                style="width: 100%"
                v-model="form.type"
              >
                <a-select-option v-for="op in invoiceTypes" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="form.type === 5">
            <a-form-model-item label="原发票号" >
              <a-input v-model="form.original_invoice_no" @blur="queryOriginalInvoice"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="部门" prop="department">
              <a-select placeholder="请选择部门" v-model="form.department">
                <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8" v-if="form.department === 1">
            <a-form-model-item label="业务类型">
              <a-select placeholder="请选择业务类型" v-model="form.order_type" >
                <a-select-option v-for="(value, key) in WmsOrderTypes" :key="key">{{ value }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="发票号" prop="invoice_no">
              <a-input v-model="form.invoice_no"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="税率" prop="tax_rate">
              <a-input v-model="form.tax_rate" suffix="%"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="去税金额" prop="price_total">
              <a-input v-model="form.price_total" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="税额" prop="tax_total">
              <a-input v-model="form.tax_total" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="税价总计" prop="tax_price_total">
              <a-input v-model="form.tax_price_total" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="开票日期" prop="open_date">
              <a-date-picker @change="onDateChange" :default-value="form.open_date"/>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="客户名称">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="交易附言">
              <a-input v-model="form.remark" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="emailFormVisible"
      :confirmLoading="emailConfirmLoading"
      :width="900"
      :maskClosable="false"
      title="电子发票发送邮件"
      @cancel="emailFormVisible = false"
      @ok="handleSendEmailSubmit"
    >
      <a-form-model layout="vertical" :model="emailForm" ref="emailForm">
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="电子发票号">
              <a-input v-model="emailForm.invoice_nos"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="邮箱地址">
              <a-select mode="tags" v-model.trim="emailForm.emails" style="width: 100%" @search="handleSearchEmails" @change="handleChangeEmail">
                <a-select-option v-for="item in invoiceEmails" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="redInfoNoVisible"
      :confirmLoading="redInfoNoLoading"
      :width="900"
      :maskClosable="false"
      title="申请红字编号"
      @cancel="redInfoNoVisible = false"
      @ok="handleApplyRedInfoNoSubmit"
    >
      <a-form-model layout="vertical" :model="redInfoNoForm" ref="redInfoNoForm">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="电子发票号">
              <a-input v-model="redInfoNoForm.invoice_no" disabled></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="红冲原因">
              <a-select v-model.trim="redInfoNoForm.red_invoice_label" style="width: 100%" @search="handleSearchEmails" @change="handleChangeEmail">
                <a-select-option v-for="item in redInvoiceLabelList" :key="item.value">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
    <a-modal
      :visible="printInvoiceVisible"
      :confirmLoading="printInvoiceLoading"
      :width="1000"
      :maskClosable="false"
      title="打印发票"
      @cancel="cancelprintInvoiceRecord"
      :footer="null"
    >
      <a-table
        size="small"
        row-key="id"
        bordered
        :pagination="false"
        :scroll="{ x: 'max-content' }"
        :columns="printInvoiceColumns"
        :data-source="printInvoiceData"
      >
        <template slot="action" slot-scope="text, record">
          <a @click="handlePrintOneInvoice(record)">{{record.einvoice_url?"下载":"打印"}}</a>
        </template>
      </a-table>
    </a-modal>
  </a-card>
</template>
<script>
import { STable, EditTable } from '@/components'
import moment from 'moment'
import { getSystemInfoOption } from '@/api/system'
import { getFirstLetter } from '@/utils/util'
import {
listInvoiceLog,
queryByInvoiceNo,
addAndUpdateInvoiceLog,
deleteInvoiceLog,
cancelOrNormal,
exportInvoiceStaticSheet,
statisticInvoiceByDepartment,
exportInvoiceDetailSheet,
cashImport,
getFmsMetaOption,
listSecondInvoiceSubject,
submitApplyInvoiceForm,
queryOriginalApplyByInvoiceNo,
cancelInvoiceApplyInfo,
getEInvoiceAndSendEmail,
printInvoice,
likeSearchEmail,
togetherSendEmail,
queryEmailsByCustomerId,
applyRedInfoNoSubmit,
getInvoiceBuyer,
queryInvoiceBuyerById,
pageInvoiceTaxTray,
onlyApplyInvoiceFormNoOrder
} from '@/api/fms'
import { Empty } from 'ant-design-vue'
import debounce from 'lodash/debounce'
import { getCustomer, downloadExcel } from '@/api/common'
export default {
  components: {
    STable,
    Empty,
    EditTable
  },
  data() {
    this.lastFetchId = 0;
    this.invoiceBuyerFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800);
    this.handleSearchEmails = debounce(this.handleSearchEmails, 800);
    this.getInvoiceBuyerInfo = debounce(this.getInvoiceBuyerInfo, 800)
    return {
      popVisible: false,
      newPopVisible: false,
      customerInfo: undefined,
      invoiceTypes: {
        1: { value: 1, name: '专票' },
        2: { value: 2, name: '普票' },
        3: { value: 3, name: '免税发票' },
        4: { value: 4, name: '奥货发票' },
        5: { value: 5, name: '红冲' }
      },
      WmsOrderTypes: {
        '1': '查验',
        '2': '暂落箱',
        '3': '仓库租赁',
        '4': '仓储行政',
        '5': '客服仓储'
      },
      invoiceTypeCodeOps: [
        { code: '004', name: '增值税专用发票' },
        { code: '007', name: '增值税普通发票' },
        { code: '026', name: '电子普通发票' },
        { code: '028', name: '电子专用发票' },
        { code: '01', name: '全电发票(专票)' },
        { code: '02', name: '全电发票(普票)' }
      ],
      invoiceStatusOps: [
        { code: 'normal', name: '正常' },
        { code: 'redoff', name: '红冲' },
        { code: 'normalred', name: '正常(已红冲)' },
        { code: 'cancel', name: '作废' }
      ],
      customerOps: [],
      fetching: false,
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create',
      queryParam: {
        open_begin_date: moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        open_end_date: moment().endOf('month').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        invoice_no: null,
        customer_name: null,
        price_total: null
      },
      userOps: [],
      // businessTypeOps: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      invoiceLogdata: [],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: '部门',
          dataIndex: 'department',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '发票类型',
          dataIndex: 'type',
          customRender: text => {
            return this.invoiceTypes[text].name
          }
        },
        {
          title: '票号',
          dataIndex: 'invoice_no'
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          customRender: text => {
            if (text !== 0) {
              return (text * 100) + '%'
            } else {
              return text
            }
          }
        },
        {
          title: '去税金额',
          dataIndex: 'price_total'
        },
        {
          title: '税额',
          dataIndex: 'tax_total'
        },
        {
          title: '税价总计',
          dataIndex: 'tax_price_total'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => {
            if (text === 'cancel') {
              return (<a-tag color = 'red' >作废</a-tag>)
            } else if (text === 'redoff') {
              return (<a-tag color = 'red' >红冲</a-tag>)
            } else if (text === 'normalred') {
              return (<a-tag color = 'orange' >正常(已红冲)</a-tag>)
            } else {
              return (<a-tag color = 'green' >正常</a-tag>)
            }
          }
        },
        {
          title: '客户名称',
          dataIndex: 'customer_name'
        },
        {
          title: '原票号',
          dataIndex: 'original_invoice_no'
        },
        {
          title: '红字编号',
          dataIndex: 'red_info_no'
        },
        {
          title: '开票日期',
          dataIndex: 'open_date',
          customRender: text => {
            return text && moment(text).format('YYYY-MM-DD')
          }
        },
        {
          title: '开票备注',
          dataIndex: 'remark'
        },
        {
          title: '业务类型',
          dataIndex: 'order_type',
          customRender: (text, record) => {
            if (record.department === 1) {
              return this.WmsOrderTypes[text]
            }
          }
        },
        {
          title: '创建日期',
          dataIndex: 'created_at',
          customRender: text => {
            return text && moment(text).format('YYYY/MM/DD HH:MM:SS')
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      form: {
        id: '',
        type: null,
        department: null,
        invoice_no: null,
        audit_record_id: null,
        customer_id: null,
        customer_name: null,
        tax_no: null,
        address: null,
        mobile: null,
        bank: null,
        bank_card_no: null,
        remark: null,
        price_total: null,
        tax_total: null,
        tax_price_total: null,
        currency: null,
        open_date: null,
        original_invoice_no: null,
        open_type: null
      },
      rules: {
        type: [{ required: true, message: '请选择发票类型', trigger: 'blur' }],
        invoice_no: [{ required: true, message: '请填写发票号', trigger: 'blur' }],
        department: [{ required: true, message: '请选择部门', trigger: 'blur' }],
        price_total: [{ required: true, message: '请填写去税金额', trigger: 'blur' }],
        tax_total: [{ required: true, message: '请填写税额', trigger: 'blur' }],
        tax_price_total: [{ required: true, message: '请填写税价总计', trigger: 'blur' }],
        open_date: [{ required: true, message: '请选择开票日期', trigger: 'blur' }],
        customer_name: [{ required: true, message: '请填写客户名称', trigger: 'blur' }],
        tax_rate: [{ required: true, message: '请填写客户名称', trigger: 'blur' }]
      },
      selectedRowKeys: [],
      selectedRows: [],
      currentData: null,
      bankOps: [],
      advanceOps: [
        { value: 1, name: `否` },
        { value: 2, name: `是` }
      ],
      applyInvoiceVisible: false,
      applyInvoiceLoading: false,
      applyInvoiceForm: {
        invoice_type: '1',
        many_together: 'N',
        taxation_method: '0',
        price_tax_mark: '1',
        invoice_list_mark: '0',
        audit_id: null,
        invoice_type_code: null,
        tax_tray_id: null,
        buyer_name: null,
        buyer_tax_no: null,
        buyer_address_phone: null,
        buyer_telphone: null,
        buyer_bank_account: null,
        buyer_account: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address_mobile: null,
        sale_company_bank_account: null,
        payee: null,
        checker: null,
        drawer: null,
        invoice_subject_data: [],
        original_invoice_no: null,
        red_invoice_label: null,
        red_info_no: null,
        invoice_open_again: '0',
        department: null
      },
      applyInvoiceRules: {
        // original_invoice_no: [{ required: true, message: '请填写原蓝票发票号', trigger: 'change' }],
        // red_invoice_label: [{ required: true, message: '请选择冲红原因', trigger: 'change' }],
        invoice_type_code: [{ required: true, message: '请选择开票类型', trigger: 'change' }],
        tax_tray_id: [{ required: true, message: '请选择税盘', trigger: 'change' }],
        buyer_name: [{ required: true, message: '请填写购买单位名称', trigger: 'blur' }],
        sale_company_name: [{ required: true, message: '请填写销货单位名称', trigger: 'blur' }],
        sale_company_tax_no: [{ required: true, message: '请填写销货单位税号', trigger: 'blur' }],
        sale_company_address_mobile: [{ required: true, message: '请填写购买单位地址电话', trigger: 'blur' }],
        sale_company_bank_account: [{ required: true, message: '请填写购买单位银行账户', trigger: 'blur' }],
        payee: [{ required: true, message: '请填写收款人', trigger: 'blur' }],
        checker: [{ required: true, message: '请填写复核人', trigger: 'blur' }],
        drawer: [{ required: true, message: '请填写开票人', trigger: 'blur' }]
      },
      taxtrayList: [],
      redInvoiceLabelList: [{ value: '01', name: '开票有误' },
        { value: '02', name: '销货退回' },
        { value: '03', name: '服务中止' },
        { value: '04', name: '销售折让' }],
      taxRateList: [{ value: 0, name: 0 },
        { value: 0.01, name: 0.01 },
        { value: 0.03, name: 0.03 },
        { value: 0.05, name: 0.05 },
        { value: 0.06, name: 0.06 },
        { value: 0.09, name: 0.09 },
        { value: 0.13, name: 0.13 },
        { value: 0.15, name: 0.15 },
        { value: 0.16, name: 0.16 },
        { value: 0.17, name: 0.17 }],
      totalInvoiceAmount: 0.00,
      invoiceSubjectColumns: [
        {
          title: `货物或应税劳务,服务名称`,
          dataIndex: `goods_name`,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  let subject = null
                  debugger
                  for (const item of this.secondInvoiceSubjectList) {
                    if (item.id === val) {
                      subject = item
                    }
                  }
                  this.invoiceSubjectData[index]['subject_id'] = subject.id
                  this.invoiceSubjectData[index]['goods_name'] = subject.subject_name
                  this.invoiceSubjectData[index]['goods_code'] = subject.subject_code
                  this.invoiceSubjectData[index]['goods_specification'] = subject.subject_spec
                  this.invoiceSubjectData[index]['goods_unit'] = subject.subject_unit
                  this.invoiceSubjectData[index]['goods_tax_rate'] = subject.tax_rate
                }}
              >
                {this.secondInvoiceSubjectList.map(op => {
                  return <a-select-option key={op.id}>{op.subject_name + op.tax_rate}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `科目编号`,
          dataIndex: `goods_code`,
          width: 200
        },
        {
          title: `规格型号`,
          dataIndex: `goods_specification`,
          width: 80
        },
        {
          title: `单位`,
          dataIndex: `goods_unit`,
          width: 80
        },
        {
          title: `数量`,
          dataIndex: `goods_quantity`,
          width: 80,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_quantity'] = val
                  if (this.invoiceSubjectData[index]['goods_price']) {
                    this.invoiceSubjectData[index]['goods_total_price'] = this.invoiceSubjectData[index]['goods_price'] * val
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                      var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                      if (this.applyInvoiceForm.price_tax_mark === '0') {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * taxRate).toFixed(2)
                      } else {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + taxRate) * taxRate).toFixed(2)
                      }
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = 0
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `单价`,
          dataIndex: `goods_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_price'] = val
                  if (this.invoiceSubjectData[index]['goods_quantity']) {
                    this.invoiceSubjectData[index]['goods_total_price'] = this.invoiceSubjectData[index]['goods_quantity'] * val
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.invoiceSubjectData[index]['goods_tax_rate'] >= 0) {
                      var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                      if (this.applyInvoiceForm.price_tax_mark === '0') {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * taxRate).toFixed(2)
                      } else {
                        this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + taxRate) * taxRate).toFixed(2)
                      }
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `金额`,
          dataIndex: `goods_total_price`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_price'] = val
                  if (this.invoiceSubjectData[index]['goods_tax_rate']) {
                    var taxRate = this.invoiceSubjectData[index]['goods_tax_rate']
                    if (this.applyInvoiceForm.price_tax_mark === '0') {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (val * taxRate).toFixed(2)
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (val / (1 + taxRate) * taxRate).toFixed(2)
                    }
                  } else {
                    this.invoiceSubjectData[index]['goods_total_tax'] = 0
                  }
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `税率`,
          dataIndex: `goods_tax_rate`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-select
                style="width: 100%"
                value={text}
                showSearch
                filterOption={this.filterOption}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_tax_rate'] = val
                  if (this.invoiceSubjectData[index]['goods_total_price']) {
                    var totalPrice = this.invoiceSubjectData[index]['goods_total_price']
                    if (this.applyInvoiceForm.price_tax_mark === '0') {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice * val).toFixed(2)
                    } else {
                      this.invoiceSubjectData[index]['goods_total_tax'] = (totalPrice / (1 + val) * val).toFixed(2)
                    }
                  }
                  this.calcTotalPrice(false)
                }}
              >
                {this.taxRateList.map(op => {
                  return <a-select-option key={op.value}>{op.name}</a-select-option>
                })}
              </a-select>
            )
          }
        },
        {
          title: `税额`,
          dataIndex: `goods_total_tax`,
          width: 100,
          customRender: (text, record, index) => {
            return (
              <a-input-number
                style="width:100%"
                value={text}
                onChange={val => {
                  this.invoiceSubjectData[index]['goods_total_tax'] = val
                  this.calcTotalPrice(false)
                }}
              />
            )
          }
        },
        {
          title: `开票备注(多票)`,
          dataIndex: `special_remark`,
          width: 100,
          extra: { type: 'text' }
        }
      ],
      invoiceSubjectData: [],
      applyInvoiceRecord: {},
      secondInvoiceSubjectList: [],
      emailFormVisible: false,
      emailConfirmLoading: false,
      emailForm: {
        invoice_no: null,
        emails: []
      },
      redInfoNoVisible: false,
      redInfoNoLoading: false,
      redInfoNoForm: {
        invoice_no: null,
        red_invoice_label: null
      },
      openAgain: false,
      printInvoiceVisible: false,
      printInvoiceLoading: false,
      printInvoiceColumns: [
        {
          title: `发票号`,
          dataIndex: `invoice_no`
        },
        {
          title: `税价总计`,
          dataIndex: `invoice_total_price_tax`
        },
        {
          title: `价格`,
          dataIndex: `invoice_total_price`
        },
        {
          title: `税额`,
          dataIndex: `invoice_total_tax`
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ],
      printInvoiceData: [],
      invoiceEmails: [],
      onlyApplyInvoice: false,
      invoiceBuyerInfo: undefined,
      invoiceBuyerOps: [],
      buyerfetching: false,
      applyInvoiceTile: '开具红字发票'
    }
  },
  created() {
    getSystemInfoOption('user').then(v => {
      this.userOps = v
    })
    // getFmsMetaOption('fms_business_type').then(v => {
    //   this.businessTypeOps = v
    // })
    getFmsMetaOption('fms_bank').then(v => {
      this.bankOps = v
    })
    listSecondInvoiceSubject({}).then(v => {
      this.secondInvoiceSubjectList = v
    })
    this.loadData()
  },
  methods: {
    moment,
    loadData() {
      listInvoiceLog(this.queryParam).then(res => {
        this.invoiceLogdata = res.list
        this.pagination.total = res.total
      })
    },
    handleOnlyApllyInvoice() {
      this.onlyApplyInvoice = true
      this.applyInvoiceVisible = true
      this.applyInvoiceForm.invoice_open_again = '0'
      this.applyInvoiceForm.invoice_type = '0'
      this.applyInvoiceForm.price_tax_mark = '1'
      this.applyInvoiceTile = '无订单单独开票'
    },
    queryTaxtrayListByDepartment() {
      if (this.applyInvoiceForm.department) {
        pageInvoiceTaxTray({ 'department': this.applyInvoiceForm.department }).then(res => {
          this.taxtrayList = res.list
          if (this.taxtrayList && this.taxtrayList.length > 0) {
              var t = this.taxtrayList[0]
              this.applyInvoiceForm.tax_tray_id = t.id
              this.applyInvoiceForm.sale_company_name = t.sale_company_name
              this.applyInvoiceForm.sale_company_tax_no = t.sale_company_tax_no
              this.applyInvoiceForm.sale_company_address = t.sale_company_address
              this.applyInvoiceForm.sale_company_mobile = t.sale_company_mobile
              this.applyInvoiceForm.sale_company_address_mobile = t.sale_company_address + t.sale_company_mobile
              this.applyInvoiceForm.sale_company_bank = t.sale_company_bank
              this.applyInvoiceForm.sale_company_account = t.sale_company_account
              this.applyInvoiceForm.sale_company_bank_account = t.sale_company_bank + t.sale_company_account
              this.applyInvoiceForm.payee = t.payee
              this.applyInvoiceForm.checker = t.checker
              this.applyInvoiceForm.drawer = t.opener
            }
        })
      }
    },
    handleApplyInvoice(type, record) {
      this.applyInvoiceVisible = true
      this.openAgain = false
      this.applyInvoiceForm.invoice_open_again = '0'
      this.applyInvoiceForm.invoice_type = '1'
      this.applyInvoiceForm.price_tax_mark = '1'
      if (type && type === 'openAgain') {
        this.openAgain = true
        this.applyInvoiceTile = '重新开票'
        this.applyInvoiceForm.invoice_open_again = '1'
        if (record.status === 'redoff') {
          this.applyInvoiceForm.original_invoice_no = record.original_invoice_no
        } else {
          this.applyInvoiceForm.original_invoice_no = record.invoice_no
        }
        this.applyInvoiceForm.invoice_type = '0'
        this.handleQueryOriginalInvoice(type)
      } else if (this.selectedRows && this.selectedRows.length === 1) {
        this.applyInvoiceTile = '开具红字发票'
        this.applyInvoiceForm.original_invoice_no = this.selectedRows[0].invoice_no
        this.handleQueryOriginalInvoice(type)
      }
    },
    handleQueryOriginalInvoice(type) {
      if (this.applyInvoiceForm.original_invoice_no) {
        this.taxtrayList = []
        const operatorType = this.openAgain ? 'openAgain' : ''
        queryOriginalApplyByInvoiceNo({ 'operator_type': operatorType, 'invoice_no': this.applyInvoiceForm.original_invoice_no }).then(v => {
          if (v) {
            if (v.invoice_apply_result_data) {
              this.applyInvoiceForm.original_invoice_code = v.invoice_apply_result_data[0].invoice_code
            }
            if (!type || type !== 'openAgain') {
              this.applyInvoiceForm.red_info_no = v.red_info_no
            }
            this.applyInvoiceForm.audit_id = v.audit_id
            this.applyInvoiceForm.invoice_type_code = v.invoice_type_code
            if (this.openAgain) {
              this.applyInvoiceForm.invoice_type = '0'
            } else {
              this.applyInvoiceForm.invoice_type = '1'
            }
            this.applyInvoiceForm.many_together = v.many_together ? v.many_together : 'N'
            this.applyInvoiceForm.taxation_method = v.taxation_method.toString()
            this.applyInvoiceForm.price_tax_mark = v.price_tax_mark.toString()
            this.applyInvoiceForm.invoice_list_mark = v.invoice_list_mark.toString()
            this.applyInvoiceForm.buyer_name = v.buyer_name
            this.applyInvoiceForm.buyer_tax_no = v.buyer_tax_no
            this.applyInvoiceForm.buyer_address_phone = v.buyer_address_phone
            this.applyInvoiceForm.buyer_telphone = v.buyer_telphone
            this.applyInvoiceForm.buyer_bank_account = v.buyer_bank_account
            this.applyInvoiceForm.buyer_account = v.buyer_account
            this.applyInvoiceForm.remarks = v.remarks
            if (v.tax_tray_info) {
              var t = v.tax_tray_info
              this.applyInvoiceForm.tax_tray_id = t.id
              this.applyInvoiceForm.sale_company_name = t.sale_company_name
              this.applyInvoiceForm.sale_company_tax_no = t.sale_company_tax_no
              this.applyInvoiceForm.sale_company_address = t.sale_company_address
              this.applyInvoiceForm.sale_company_mobile = t.sale_company_mobile
              this.applyInvoiceForm.sale_company_address_mobile = t.sale_company_address + t.sale_company_mobile
              this.applyInvoiceForm.sale_company_bank = t.sale_company_bank
              this.applyInvoiceForm.sale_company_account = t.sale_company_account
              this.applyInvoiceForm.sale_company_bank_account = t.sale_company_bank + t.sale_company_account
              this.applyInvoiceForm.payee = t.payee
              this.applyInvoiceForm.checker = t.checker
              this.applyInvoiceForm.drawer = t.opener
            }
            if (v.tax_tray_info_list) {
              v.tax_tray_info_list.forEach(t => {
                this.taxtrayList.push(t)
              })
            }
            if (v.invoice_subject_data) {
              this.invoiceSubjectData = []
              console.info(v.invoice_subject_data)
              var numFlag = -1;
              if (this.openAgain) {
                numFlag = 1;
              }
              v.invoice_subject_data.forEach(s => {
                this.invoiceSubjectData.push(
                  { 'goods_code': s.goods_code,
                  'goods_name': s.goods_name,
                  'goods_specification': s.goods_specification,
                  'goods_unit': s.goods_unit,
                  'goods_quantity': s.goods_quantity ? s.goods_quantity * numFlag : null,
                  'goods_price': s.goods_price,
                  'goods_total_price': s.goods_total_price * numFlag,
                  'goods_tax_rate': s.goods_tax_rate,
                  'goods_total_tax': s.goods_total_tax * numFlag,
                  'goods_original_line_no': s.goods_line_no,
                  'special_remark': s.special_remark
                  }
                )
              })
              this.totalInvoiceAmount = 0
              this.invoiceSubjectData.forEach(s => {
                this.totalInvoiceAmount = this.totalInvoiceAmount + s.goods_total_price
                if (this.applyInvoiceForm.price_tax_mark === '0') {
                  this.totalInvoiceAmount = this.totalInvoiceAmount + parseFloat(s.goods_total_tax)
                }
              })
            }
          }
        })
      }
    },
    changeSaleInvoiceInfo() {
      this.taxtrayList.forEach(t => {
        if (t.id === this.applyInvoiceForm.tax_tray_id) {
            this.applyInvoiceForm.tax_tray_id = t.id
            this.applyInvoiceForm.sale_company_name = t.sale_company_name
            this.applyInvoiceForm.sale_company_tax_no = t.sale_company_tax_no
            this.applyInvoiceForm.sale_company_address = t.sale_company_address
            this.applyInvoiceForm.sale_company_mobile = t.sale_company_mobile
            this.applyInvoiceForm.sale_company_address_mobile = t.sale_company_address + t.sale_company_mobile
            this.applyInvoiceForm.sale_company_bank = t.sale_company_bank
            this.applyInvoiceForm.sale_company_account = t.sale_company_account
            this.applyInvoiceForm.sale_company_bank_account = t.sale_company_bank + t.sale_company_account
            this.applyInvoiceForm.payee = t.payee
            this.applyInvoiceForm.checker = t.checker
            this.applyInvoiceForm.drawer = t.opener
        }
      })
    },
    applyInvoiceCancel() {
      this.applyInvoiceLoading = false
      this.applyInvoiceVisible = false
      this.openAgain = false
      this.onlyApplyInvoice = false
      this.taxtrayList = []
      this.applyInvoiceTile = '开具红字发票'
      this.applyInvoiceForm = {
        original_invoice_no: null,
        red_invoice_label: null,
        red_info_no: null,
        invoice_type: '0',
        many_together: 'N',
        taxation_method: '0',
        price_tax_mark: '0',
        invoice_list_mark: '0',
        audit_id: null,
        invoice_type_code: null,
        tax_tray_id: null,
        buyer_name: null,
        sale_company_name: null,
        sale_company_tax_no: null,
        sale_company_address_mobile: null,
        sale_company_bank_account: null,
        payee: null,
        checker: null,
        drawer: null,
        invoice_open_again: null,
        invoice_subject_data: []
      }
      this.invoiceSubjectData = []
    },
    getInvoiceSubjectTableDate(data) {
      this.invoiceSubjectData = data
    },
    applyInvoiceOk() {
      if (!this.openAgain && !this.onlyApplyInvoice) {
        if (this.applyInvoiceForm.invoice_type_code === '004' || this.applyInvoiceForm.invoice_type_code === '028' || this.applyInvoiceForm.invoice_type_code === '01') {
          if (!this.applyInvoiceForm.red_info_no) {
            this.$message.warning('专票开具红冲发票必须填写“红字编号”');
            return
          }
        }
      }
      this.applyInvoiceLoading = true
      this.$refs.applyInvoiceForm.validate(valid => {
        if (valid) {
          this.applyInvoiceForm.invoice_subject_data = this.invoiceSubjectData
          if (!this.onlyApplyInvoice) {
            submitApplyInvoiceForm(this.applyInvoiceForm).then(v => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.applyInvoiceCancel();
              if (this.openAgain) {
                this.printInvoiceData = v
                this.printInvoiceVisible = true
              }
              this.loadData()
            }).catch(_ => {
              this.applyInvoiceLoading = false
            })
          } else if (this.onlyApplyInvoice) {
            onlyApplyInvoiceFormNoOrder(this.applyInvoiceForm).then(v => {
              this.$notification['success']({
                message: '提示',
                description: this.isEdit ? '编辑成功' : '创建成功.'
              })
              this.applyInvoiceCancel();
              if (this.openAgain || this.onlyApplyInvoice) {
                this.printInvoiceData = v
                this.printInvoiceVisible = true
              }
              this.loadData()
            }).catch(_ => {
              this.applyInvoiceLoading = false
            })
          }
        } else {
          this.applyInvoiceLoading = false
        }
      })
    },
    onDateChange(date, dateString) {
      if (date) {
        const startDate = date.startOf('day').format('YYYY-MM-DD HH:MM:SS');
        this.form.open_date = startDate
      } else {
        this.form.open_date = null
      }
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        this.fetching = false;
      })
    },
    getDepartmentCustomerEdit(value) {
      this.lastFetchId += 1;
      const fetchId = this.lastFetchId;
      this.customerOps = [];
      this.fetching = true;
      getCustomer({
        department: null,
        customerName: value
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return;
        }
        this.customerOps = res
        if (res && res.length > 0) {
          this.customerInfo = {
            'key': res[0].value, 'label': res[0].name
          }
        }
        this.fetching = false;
      })
    },
    changeCustomerInfo(value) {
      if (typeof value !== 'undefined') {
        this.customerInfo = value
        this.form.customer_name = value.label
        this.form.customer_id = value.key
        this.customerOps = []
        this.fetching = false
      } else {
        this.customerInfo = value
        this.form.pay_name = ''
        this.form.customer_id = ''
        this.customerOps = []
        this.fetching = false
      }
    },
    queryOriginalInvoice() {
      if (this.form.original_invoice_no) {
        queryByInvoiceNo({ 'invoice_no': this.form.original_invoice_no }).then(res => {
          // this.form = res
          this.form.invoice_no = null
          this.form.id = null
          this.form.price_total = res.price_total * -1
          this.form.tax_total = res.tax_total * -1
          this.form.tax_price_total = res.tax_price_total * -1
          this.form.audit_record_id = res.audit_record_id
          this.form.customer_id = res.customer_id
          this.form.customer_name = res.customer_name
          this.customerInfo = {
            'key': res.customer_id, 'label': res.customer_name
          }
          this.form.department = res.department
          this.form.currency = res.currency
          this.form.order_type = res.order_type
          this.form.tax_rate = res.tax_rate ? res.tax_rate * 100 : 0
        })
      }
    },
    handleCreate() {
      this.modalFlag = 'create'
      this.handleFormReset()
      this.formVisible = true
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.handleFormReset()
      this.customerInfo = {
            'key': record.customer_id, 'label': record.customer_name
          }
      this.form = record
      this.form.open_date = moment(this.form.open_date).startOf('day').format('YYYY-MM-DD HH:MM:SS');
      console.info(this.form.open_date)
      this.formVisible = true
    },
    handleFormReset() {
      this.form = {
        id: '',
        type: null,
        department: null,
        invoice_no: null,
        audit_record_id: null,
        customer_id: null,
        customer_name: null,
        tax_no: null,
        address: null,
        mobile: null,
        bank: null,
        bank_card_no: null,
        remark: null,
        price_total: null,
        tax_total: null,
        tax_price_total: null,
        tax_rate: null,
        order_type: null,
        currency: null,
        open_date: null,
        original_invoice_no: null
      }
    },
    handleCancelOrNormal(record) {
      let context
      let status
      if (record.status && record.status === 'cancel') {
        context = '确定要还原？';
        status = 'normal'
      } else {
        context = '确定要作废？';
        status = 'cancel'
      }
      this.$confirm({
        title: '确认提示',
        content: context,
        onOk: () => {
          if (status === 'cancel') {
            cancelInvoiceApplyInfo({ 'invoiceLogId': record.id }).then(_ => {
              this.$notification['success']({
                message: '提示',
                description: '修改成功.'
              })
              this.loadData()
            })
          } else {
            cancelOrNormal({ 'invoice_log_id': record.id, 'invoice_status': status }).then(_ => {
              this.$notification['success']({
                message: '提示',
                description: '修改成功.'
              })
              this.loadData()
            })
          }
        }
      })
    },
    handleDelete(record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          deleteInvoiceLog({ 'invoice_log_id': record.id }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    onCreateDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['open_begin_date'] = startDate
        this.queryParam['open_end_date'] = endDate
      } else {
        this.queryParam['open_begin_date'] = null
        this.queryParam['open_end_date'] = null
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.form.open_date = moment(this.form.open_date).valueOf()
          addAndUpdateInvoiceLog(this.form)
            .then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              this.$refs.table.refresh(this.modalFlag === 'create')
            })
            .catch(_ => {
              this.confirmLoading = false
            })
        }
      })
    },
    receiveAccountChange(val, option) {
      this.bankOps.forEach(data => {
        if (data.bank_account === val) {
          this.form.receive_bank = data.bank
        }
      })
    },
    handleImport(obj) {
      var formData = new FormData()
      formData.append('uploadFile', obj.file)
      cashImport(formData).then(v => {
        obj.onSuccess()
        this.$notification['success']({
          message: '提示',
          description: '导入成功'
        })
        this.$refs.table.refresh(true)
      })
    },
    monthDateChange(date, dateStr) {
      if (dateStr[0]) {
        this.queryParam['open_begin_date'] = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.queryParam['open_end_date'] = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.queryParam['open_begin_date'] = null
        this.queryParam['open_end_date'] = null
      }
    },
    exportInvoiceStatic() {
      const startDate = this.queryParam['open_begin_date']
      const endDate = this.queryParam['open_end_date']
      if (startDate || endDate) {
        exportInvoiceStaticSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false
        })
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择导出月份'
        })
      }
    },
    newExportInvoiceStatic() {
      const startDate = this.queryParam['open_begin_date']
      const endDate = this.queryParam['open_end_date']
      if (startDate || endDate) {
        statisticInvoiceByDepartment({ 'open_start_time': startDate, 'open_end_time': endDate }).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.newPopVisible = false
        })
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择导出月份'
        })
      }
    },
    exportInvoiceDetail() {
      const startDate = this.queryParam['open_begin_date']
      const endDate = this.queryParam['open_end_date']
      if (startDate || endDate) {
        exportInvoiceDetailSheet(this.queryParam).then(res => {
          if (res !== null) {
            const fileInfo = { fileName: res }
            downloadExcel(fileInfo).then(downRes => {
              const data = downRes.data
              let fileName = ''
              const contentDisposition = downRes.headers['content-disposition']
              if (contentDisposition) {
                fileName = decodeURI(escape(contentDisposition.split('=')[1]))
              }
              const url = window.URL.createObjectURL(
                new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
              )
              const link = document.createElement('a')
              link.style.display = 'none'
              link.href = url
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
            })
          }
        }).finally(_ => {
          this.popVisible = false
        })
      } else {
        this.$notification['warn']({
          message: '提示',
          description: '请选择开票日期'
        })
      }
    },
    handleSendEmail() {
      if (!this.selectedRows || this.selectedRows.length === 0) {
        this.$message.warning('请选择发票记录!');
        return
      }
      this.emailFormVisible = true
      this.emailForm = {
        invoice_nos: [],
        emails: []
      }
      this.selectedRows.forEach(element => {
        this.emailForm.invoice_nos.push(element.invoice_no);
      });
      queryEmailsByCustomerId({ 'customer_id': this.selectedRows[0].customer_id }).then(emails => {
        this.invoiceEmails = emails
        if (emails.length === 1) {
          this.emailForm.emails = []
          this.emailForm.emails.push(emails[0])
        }
      })
    },
    handleSendEmailSubmit() {
      this.$refs.emailForm.validate(valid => {
        if (valid) {
          togetherSendEmail(this.emailForm)
            .then(_ => {
              this.emailConfirmLoading = false
              this.emailFormVisible = false
              this.$notification['success']({
                message: '提示',
                description: '发送电子发票邮件成功'
              })
              this.$refs.table.refresh(this.modalFlag === 'create')
            })
            .catch(_ => {
              this.emailConfirmLoading = false
            })
        }
      })
    },
    handlePrintInvoice(record) {
      printInvoice({ 'invoice_no': record.invoice_no }).then(_ => {
        this.$notification['success']({
          message: '提示',
          description: '发票打印成功'
        })
        record.is_print = 1
      })
    },
    handleOpenEinvoiceUrl(record) {
      getEInvoiceAndSendEmail({ 'invoice_no': record.invoice_no }).then(v => {
        if (v) {
          window.open(v, true);
        }
      })
    },
    batchHandleOpenEinvoiceUrl() {
      console.info(this.selectedRows)
      this.selectedRows.forEach(v => {
        getEInvoiceAndSendEmail({ 'invoice_no': v.invoice_no }).then(v => {
          if (v) {
            setTimeout(() => {
              window.open(v, '_blank');
            }, 800);
          }
        })
      })
    },
    handlePrintOneInvoice(record) {
      console.info(record.einvoice_url)
      if (record.einvoice_url) {
        getEInvoiceAndSendEmail({ 'invoice_no': record.invoice_no }).then(v => {
          if (v) {
            window.open(v, true);
          }
        })
      } else {
        this.printInvoiceLoading = true;
        printInvoice({ 'invoice_no': record.invoice_no }).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '发票打印成功'
          })
        }).finally(() => {
          this.printInvoiceLoading = false;
        })
      }
    },
    cancelprintInvoiceRecord() {
      this.printInvoiceVisible = false
      this.printInvoiceLoading = false;
      this.printInvoiceData = []
    },
    handleChangeTable(page) {
      this.pagination.current = page.current
      this.pagination.pageSize = page.pageSize
      this.queryParam['page'] = page.current
      this.queryParam['page_size'] = page.pageSize
      this.loadData()
    },
    handleSearchEmails(value) {
      if (value && value.length > 2) {
        likeSearchEmail({ 'email': value }).then(v => {
          this.invoiceEmails = v
        })
      }
    },
    handleChangeEmail(value) {
      console.info(value)
      this.emailForm.emails = []
      value.forEach(element => {
        this.emailForm.emails.push(element)
      });
      console.info(this.emailForm.emails)
    },
    handleApplyRedInfoNo(record) {
      this.redInfoNoVisible = true;
      this.redInfoNoForm.invoice_no = record.invoice_no
    },
    handleApplyRedInfoNoSubmit() {
      this.redInfoNoLoading = true;
      applyRedInfoNoSubmit(this.redInfoNoForm).then(_ => {
          this.$notification['success']({
            message: '提示',
            description: '红冲成功'
          })
          this.redInfoNoVisible = false;
          this.loadData()
      }).finally(() => {
        this.redInfoNoLoading = false;
      })
    },
    getInvoiceBuyerInfo(value) {
      this.invoiceBuyerFetchId += 1
      const fetchId = this.invoiceBuyerFetchId
      this.invoiceBuyerOps = []
      this.buyerfetching = true
      getInvoiceBuyer({
        buyer_name: value
      }).then(res => {
        if (fetchId !== this.invoiceBuyerFetchId) {
          return
        }
        this.invoiceBuyerOps = res
        this.buyerfetching = false
      })
    },
    changeInvoiceBuyerInfo(value) {
      this.invoiceBuyerInfo = value
      queryInvoiceBuyerById({ id: value.key }).then(res => {
        this.applyInvoiceForm.buyer_id = res.buyer_id
        this.applyInvoiceForm.buyer_name = res.buyer_name
        this.applyInvoiceForm.buyer_tax_no = res.buyer_tax_no
        this.applyInvoiceForm.buyer_address_phone = res.buyer_address_phone
        this.applyInvoiceForm.buyer_telphone = res.buyer_telphone
        this.applyInvoiceForm.buyer_bank_account = res.buyer_bank_account
        this.applyInvoiceForm.buyer_account = res.buyer_account
      })
      this.invoiceBuyerOps = []
      this.buyerfetching = false
    }
  }
}
</script>
